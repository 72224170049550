<template>
    <div class="homereviews" v-if="this.reviews && this.reviews.data && this.reviews.data.length > 0">
        <div class="wrapper">

            <div class="homereviews__heading">
                <h2>Expert Car Reviews</h2>
                <p>Our motor experts review new cars available to test-drive and buy on the Irish market</p>
            </div>

            <div class="homereviews__wrapper">

                <div class="homereviews__column homereviews__column--featured">
                    <div class="homereviews__review">
                        <router-link class="homereviews__review_link" :to="{name: 'review', params: { slug: this.reviews.data[0].slug }}">
                            <div class="homereviews__review_imagewrapper">
                                <v-lazy-image  :src="this.reviews.data[0].overview_image" :src-placeholder="placeholderLazyImg" class="homereviews__review_image img-responsive" :alt="this.reviews.data[0].title" width="625" height="490" />
                            </div>
                        </router-link>
                        <div class="homereviews__review_body">
                            <router-link class="homereviews__review_link" :to="{name: 'review', params: { slug: this.reviews.data[0].slug }}">
                              <h2>{{ this.reviews.data[0].title }}</h2>
                              <small>{{ formattedCreatedAtDate }} </small>
                              <span v-html="shortenText"></span>
                            </router-link>

                            <router-link class="homereviews__review_cta" :to="{name: 'review', params: { slug: this.reviews.data[0].slug }}">Read the full review</router-link>
                        </div>
                    </div>
                </div>

                <div class="homereviews__column">
                    <home-review-tile v-for="(review, index) in shortReviews" :key="index" :index="index" :review="review"></home-review-tile>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import HomeReviewTile from "./partials/tiles/HomeReviewTile"
    import VLazyImage from "v-lazy-image"
    import {mapState,  mapMutations, mapActions} from 'vuex'
    var moment = require('moment')

    export default {
        name: "HomeReviews",
        components: {
            HomeReviewTile,
            VLazyImage
        },
        data: function () {
            return {
            }
        },

        computed: {
            ...mapState([]),
            formattedCreatedAtDate() {
                return moment(this.reviews.data[0].created_at).format('Do MMMM YYYY');
            },

            imageUrl() {
                return this.reviews.data[0].overview_image != null ? "background-image: url('" + this.reviews.data[0].overview_image + "');" : '';
            },

            shortReviews() {
                return this.reviews.data.slice(1, 4);
            },

            shortenText() {
                if (this.reviews.data[0].overview_text.length > 400)
                    return this.reviews.data[0].overview_text.substring(0, 400) + '...';
                else
                    return this.reviews.data[0].overview_text;
            },
            placeholderLazyImg(){
                return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
            },
            reviews() {
                return this.$store.state.reviews.reviews[this.$router.currentRoute.path];
            },
        },
        mounted() {
            if (!this.reviews) {
                this.getReviews();
            }
        },
        serverPrefetch() {
            return this.getReviews();
        },
        methods: {
            ...mapMutations([]),
            ...mapActions([]),
            async getReviews() {
                await this.$store.dispatch('reviews/fetchReviews', this.$router.currentRoute.path); // payload must be a string
            }
        }
    }
</script>

<style scoped>

</style>
